<template>
  <!--clan create modal window -->
 <CreateClan @CreateClanCancel="CreateClanCancel" @CreateClanSuccess="CreateClanSuccess" v-if="create_clan_page_visible"></CreateClan>

  <div class="wood_cont create_clan">
    <img class="wood" src="../assets/Upgrade_assets/upgrade.png"/>
  </div>

  <div class="clans">
    <!--clan create modal window - create -->
    <div class="make_clan">
      <img src="../assets/clans/clans_frame.png" class="make-clan-frame"/>
      <div class="make_clans_box">
        <form><input type="text" value="ENTER CLAN NAME" onfocus="this.value=''"/> <br/></form>

        <div class="make-clan-small-box">
          <div class="mini_button" @click="Search()">
            <img src="../assets/clans/search.svg"/>
          </div>
          <div class="mini_button" @click="CreateClan()">
            <img src="../assets/clans/change.svg"/>
          </div>
        </div>
      </div>
    </div>

    <!--clans list-->
    <div class="_frame white scroll-add">
      <div class="block" v-for="clan in clans_list" v-bind:key="clan" v-on:click="VisitClanPage(clan.ID)">
        <img src="../assets//MoneyImages/clan_img.png"/>

        <div class="block_text">
          <span>{{ clan.Name }}</span>
          <span>silver: {{ clan.Silver }}</span>
          <span>members: {{ clan.NumberOfMembers }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script>
import axios from "axios";
import CreateClan from "@/components/ClansView__CreateClan.vue";

export default {
  components: {
    CreateClan,
  },
  data() {
    return {
      create_clan_page_visible: false,
      refreshed: true,
      clans_list: [
        {
          "ID": 1,
          "Name": "Test Clan",
          "OwnerUsername": "niumandzi",
          "Silver": 0,
          "NumberOfMembers": 1,
          "Type": ""
        },
      ]
    }
  },
  mounted() {
    this.GetClansList();
  },
  methods: {
    CreateClan() {
      this.create_clan_page_visible = true;
    },

    CreateClanCancel(){
      this.create_clan_page_visible = false;
    },

    CreateClanSuccess(clanID){
      let page_type = 2;
      this.$emit('ChangePage', {page_type, clanID});
    },

    Search() {},

    VisitClanPage(ClanID){
      let page_type = 2;
      this.$emit('ChangePage', {page_type, ClanID});
    },

    async RefreshData(){
      this.GetClansList().then(() => {
        this.refreshed = true;
        console.log("ClansListComponent refreshed")
      });
    },

    //api

    async GetClansList(){
      let data = {
        "page": 0,
        "list_amount": 5,
      };
      await this.GetClansListRequest(data);
    },

    async GetClansListRequest(data){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url, data)
        .then((response) => {
          this.clans_list = response.data;
          console.log("on " + url, response);
        })
        .catch((error) => {console.log("on " + url, error);});
    },
  }
}
</script>