<template>
  <div class="debug_console">
    <p>upload time: 2:01 13.10.2024</p>
    <p v-for="(msg) of logs" v-bind:key="msg">
        {{ msg[0] }}|{{msg[1]}}
    </p>
  </div>
</template>

<style>
.debug_console{
    margin-top: 10vh;
    margin-left: 5vw;
    width: 90vw;
    height: 30vh;
    z-index: 15;
    background-color:rgb(49, 51, 56, 0.7);
    position: absolute;
    color: rgb(0, 175, 0);
    padding: 5%;
    overflow-x: scroll;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
</style>

<script>
export default {
    props: ['logs'],
};
</script>
