<template>
  <!--Clans - default page-->
  <Clanslist ref="ClansListComponent" @ChangePage="ChangePage" v-if="page_type == 0"></Clanslist>
  <img style="position: absolute; left: 100px; top: 100px; z-index: 21" src = "../assets/clans/button_cross.svg" @click="ChangePage(2, 0)">
  <!--Clan for owner/user-->
  <ClanInfo ref="ClanInfoComponent" :clanID="clanID" v-if="page_type == 1"></ClanInfo>
</template>

<style>

.centring{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.cross_but{
  position: absolute;
  width: 30px;
  height: 30px;
  margin-left: 160px;
  margin-bottom: 100px;
  z-index: 20;
}

.cross_but:active{
  -webkit-animation: Scale 0.1s ;
  animation: Scale   0.1s;
  animation-timing-function: ease-in-out;
}


.scroll-add{
  overflow-y: scroll;
  max-height: 500px;
  margin-bottom: 90px;
  z-index: 1;
}
.frame{
  position: relative;
}
.m_rec input {
  outline-width: 0;
  color: white;
  top: 5px;
  position: relative;
  width: 100px;
  height: 20px;
  text-align: center;
}
.clans{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.create_clan {
  top: 0;
}

.white {
  color: white;
}

.make-clan-frame {
  width: 350px;
  height: 75px;
  position: absolute;
}

.make_clan form {
  position: relative;
  top: 40px;
  border: 2px red;
}

.make_clan input {
  color: rgba(135, 105, 83, 1);
  width: 220px;
}

input[type="text"]:focus {
  outline-width: 0;
}

.make_clan {
  width: 100vw;
  height: 75px;
  display: flex;
  justify-content: center;
  margin-top: 80px;;
  z-index: 2;
}

.make_clans_box {
  width: 300px;
  height: 75px;
  display: flex;
  justify-content: space-around;
}

.make-clan-small-box {
  height: 50px;
  width: 65px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-self: end;
}

.make-clan-small-box .mini_button {
  align-self: center;
}

.leave {
  z-index: 24;
  position: absolute;
}

.leave .continue_button {
  left: 50px;
  top: 110px;
  position: absolute;
}

.leave span {
  font-size: 20px;
  width: 200px;
  top: 60px;
  position: absolute;
}

.leave .m_rec {
  left: 40px;
  top: -10px;
}

.continue_button {
  background-image: url("../assets/svgs/done.svg");
}

.enter_name {
  top: -10px;
}

.clans-page .make-clan-frame {
  height: 150px;
}

.clans-page-box {
  width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
}

.clans-page-box span {
  position: absolute;
}

.clans-page-box span:first-child {
  font-size: 24px;
  top: 20px;
  left: 70px;
  width: 200px;
  text-align: center;
}

.clans-page-box span:nth-child(2) {
  top: 60px;
  left: 25px;
  font-size: 12px;
}

.clans-page-box span:nth-child(3) {
  top: 60px;
  left: 230px;
  font-size: 12px;
}

.clans-page-box span:nth-child(4) {
  top: 85px;
  left: 25px;
  font-size: 18px;
}

.clans-page-box span:nth-child(5) {
  top: 105px;
  left: 25px;
  font-size: 12px;
}

.clans-page-box span:nth-child(6) {
  top: 105px;
  left: 150px;
  font-size: 12px;
}

.clans-page-box .mini_button {
  margin-top: 98px;
  margin-left: 300px;
  z-index: 25;
}

@keyframes Scale{
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(0.9);
    }
}
</style>

<script>
import Clanslist from "@/components/ClansView__Clanslist.vue";
import ClanInfo from "@/components/ClansView__ClanInfo.vue"

export default {
  components: {
    Clanslist,
    ClanInfo,
  },
  data() {
    return {
      page_type: 0,
      changed: 0,
      clanID: 0,
    };
  },
  mounted() {
    this.$emit("component_loading", { name: "ClansView" });
  },
  watch: {
    changed(){
      this.page_type = (this.page_type + 1) % 2;
      if (this.page_type == 1) {
        setTimeout(() => {this.$refs.ClanInfoComponent.RefreshData();}, 100)
      } else {
        setTimeout(() => {this.$refs.ClansListComponent.RefreshData();}, 100);
      }
    }
  },
  methods: {
    ChangePage(data) {
      this.clanID = data.ClanID;
      this.changed += 1;
    },
  },
};
</script>
