<template>
  <div class="shadow_end leave" @click="CancelLeaving()">
    <div class="frame">
      <div class="skull img_wait"><img src="../assets/pistons_svg.svg"></div>
      <div class="money_box">
        <span>Want to leave?</span>
      </div>
      <div class="continue_button" @click="ConfirmLeave()"></div>
    </div>
  </div>
</template>

<style>

</style>

<script>
import axios from "axios";

export default {
  data() {
  },
  methods: {
    CancelLeaving() {
      this.$emit('CancelLeaving');
    },
    ConfirmLeave() {
      this.LeaveClanRequest();
    },

    async LeaveClanRequest() {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/leave";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get(url)
        .then((response) => {
          console.log("on " + url, response);
          this.$emit('SetUserType', 'user');
          this.$emit('CancelLeaving');
        })
        .catch((error) => {console.log("on " + url, error);});
    }
  }
}
</script>