<template>
  <div class="centring shadow_end leave">
    <div class="frame">
      <div class="skull img_wait enter_name">
        <img src="../assets/clans/enter_clan_name.svg"/>
      </div>
      <div class="cross_but"><img src = "../assets/clans/button_cross.svg" @click="Cancel()"></div>
      <div class="money_box">
        <div class="m_rec">
          <input v-model="tmp_clan_name" placeholder="ENTER CLAN"/>
          <div class="s_m_rec"></div>
        </div>
      </div>

      <div class="continue_button" @click="ChangeName()"></div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {
  data() {
    return {
      tmp_clan_name: ''
    }
  },
  methods: {
    ChangeName() {
      // Note: request has not been done yet
      this.$emit('SetClanName', this.tmp_clan_name);
      this.$emit('CancelEditingClan');
      this.tmp_clan_name = '';
    },

    Cancel(){
      this.$emit('CancelEditingClan');
    }
  }
}
</script>