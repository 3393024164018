<template>
  <LeaveClan @CancelLeaving="CancelLeaving" @SetUserType="SetUserType" v-if="leave_clan_visible"></LeaveClan>

  <div class="wood_cont create_clan">
    <img class="wood" src="../assets/Upgrade_assets/upgrade.png"/>
  </div>

  <div class="clans clans-page">

    <ChangeClanName @CancelEditingClan="CancelEditingClan" @SetClanName="SetClanName" v-if="change_clan_name_visible"></ChangeClanName>

    <!--clan info-->
    <div class="make_clan">
      <img src="../assets/clans/clans_frame.png" class="make-clan-frame"/>
      <div class="clans-page-box">
        <span>{{ clan.Name }}</span>
        <span>{{ clan.Silver }} silver</span>
        <span>{{ clan.NumberOfMembers }} members</span>
        <span>{{ clan.Owner.Username }}</span>
        <span>{{ clan.Owner.Silver }} silver</span>
        <span>{{ clan.Owner.HorseLvl }} lvl</span>

        <!--buttons-->
        <div v-if="clan.Type != 'owner' && clan.Type != 'member'" class="mini_button" @click="JoinClan()"><img src="../assets/clans/btn_plus.svg"/></div>
        <div v-if="clan.Type == 'owner'" class="mini_button" @click="EditClan()"><img src="../assets/clans/change.svg"/></div>
        <div v-if="clan.Type == 'member'" class="mini_button" @click="LeaveClan()"><img src="../assets/clans/cross.svg"/></div>
      </div>
    </div>

    <!--members list-->
    <div class="_frame white scroll-add">
      <div class="block" v-for="member in clan_members" v-bind:key="member">
        <img src="../assets/MoneyImages/person1.svg"/>
        <div class="block_text">
          <span>{{ member.username }}</span>
          <span>{{ member.silver }} silver</span>
        </div>
        <div class="block_text">
          <span>level {{ member.horse_lvl }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script>
import axios from "axios";
import LeaveClan from "@/components/ClansView__LeaveClan.vue";
import ChangeClanName from "@/components/ClansView__ChangeClanName.vue";

export default {
  components: {
    LeaveClan,
    ChangeClanName,
  },
  props: ["clanID"],
  data() {
    return {
      change_clan_name_visible: false,
      leave_clan_visible: false,

      refreshed: 0,

      clan: {
        "ID": 2,
        "Name": "2",
        "Silver": 0,
        "NumberOfMembers": 1,
        "Link": null,
        "IsOpen": false,
        "Owner": {
          "Username": "2",
          "Silver": 0,
          "HorseLvl": 1
        },
        "Type": "member"
      },

      clan_members: [
        {
          "id": 0,
          "username": "user-tester#1",
          "silver": 99,
          "horse_lvl": 12
        },
      ],
    };
  },
  watch: {
    refreshed() {
      if (this.refreshed == 2){
        this.refreshed = 0;
        console.log("ClanInfoComponent refreshed")
      }
    },
  },
  methods: {
    JoinClan(){
      this.JoinClanRequest();
    },

    EditClan(){
      this.change_clan_name_visible = true;
    },

    CancelEditingClan(){
      this.change_clan_name_visible = false;
    },

    SetClanName(clan_name){
      this.clan.stats.name = clan_name;
    },

    LeaveClan(){
      this.leave_clan_visible = true;
    },

    CancelLeaving(){
      this.leave_clan_visible = false;
    },

    SetUserType(type){
      this.clan.type = type;
    },

    async RefreshData(){
      this.GetClanInfoRequest(this.clanID).then(() => this.refreshed += 1);

      let data = {
        "clan_id": this.clanID,
        "page": 0,
        "list_amount": 5
      };
      this.GetClanMembersRequest(data).then(() => this.refreshed += 1);
    },

    //api

    async JoinClanRequest(){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/" + this.clanID + "/join";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url)
        .then((response) => {
          this.clan.type = 'member';
          console.log("on " + url, response);
        })
        .catch((error) => {console.log("on " + url, error);});
    },

    async GetClanInfoRequest(clanID){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/info/" + clanID;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get(url)
        .then((response) => {
          this.clan = response.data;
          console.log("on " + url, response);
        })
        .catch((error) => {console.log("on " + url, error);});
    },

    async GetClanMembersRequest(data){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/members";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url, data)
        .then((response) => {
          this.clan_members = response.data;
          console.log("on " + url, response);
        })
        .catch((error) => {console.log("on " + url, error);});
    },
  },
}
</script>